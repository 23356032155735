/* eslint-disable max-len */

import { defineMessages } from 'react-intl'

export default defineMessages({
  pageTitle: 'IBE - Internet Booking Engine für die Tourismusbranche',
  title: 'Was ist eine IBE',
  contactTitle: 'Möchten Sie mehr über Internet Booking Engines erfahren',
  copy: 'IBE ist die Abkürzung für “Internet Booking Engine” (Deutsch: Internet-Buchungsmaschine). Die Abkürzung IBE ist vor allem in der Tourismusbranche geläufig. Dort werden IBEs genutzt, um online Pauschalreisen und Reisekomponenten, wie Flüge (Flight only) und Übernachtungen (Hotel only), zu verkaufen. In den letzten Jahren werden über Internet Booking Engines zunehmend als Ergänzung Aktivitäten am Urlaubsort verkauft. Aber auch der Verkauf von ergänzenden Leistungen, wie Transfer, Reiseversicherungen und Mietwagen, wird von modernen IBEs gemanaged. Internet Booking Engines sind Wegbereiter für Pauschalreisen, da mit ihnen Reisekomponenten und ergänzende Leistungen paketiert und zu einem Gesamtpreis verkauft werden können.',
  offerTitle: 'mightyC®',
  offerCopy: '<ul><li>Communication with decentralized content sources</li><li>Unification of your content from different content sources</li><li>Central data management and aggregation</li><li>Management of non-bookable data to make it available, for example, to Internet Booking Engines (IBE)</li><li>Error-tolerant searches for your content</li><li>Content-based page construction, offering explicit SEO advantages</li><li>Grouping of content information from different sources</li><li>Significant added value in conjunction with Player Hub technologies such as that from Peakwork</li></ul>'
})
