import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import Layout from '../../../components/Layout/index'
import SEO from '../../../components/seo'
import messages from '../../../messages/pageTitles.lang'
import metaDescription from '../../../messages/pageMetaDescriptions.lang'
import IbePage from '../../../components/IbePage'

const IndexPage = () => {
  const { formatMessage, locale } = useIntl()

  return (
    <Layout>
      <SEO
        title={formatMessage(messages.TitleIBE)}
        description={formatMessage(metaDescription.ibeDescription)}
        lang={locale}
      />
      <IbePage />
    </Layout>
  )
}

export default IndexPage
