// @flow

import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import { StaticImage } from 'gatsby-plugin-image'
import { Link, useIntl } from 'gatsby-plugin-intl'
import React from 'react'
import ContactForm from '../../components/ContactPage/ContactForm'
import SubpageHeroTeaser from '../../components/SubpageHeroTeaser'
import { Col, Container, Row } from '../../components/basic/Grid'

import messages from './IbePage.lang'
import baseStyles from './IbePage.scss'

/* eslint-disable max-len */
const imageDesktop = (
  <StaticImage
    src="../../images/heroteaser/partner_heroteaser_bg_desktop.jpg"
    alt=""
    objectFit="cover"
    className="image"
  />
)
const ibeInfoGraphic = (
  <StaticImage
    src="../../images/pictures/ibe-quickstart-by-freshcells.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
/* eslint-enable max-len */

type Props = {
  ...StyleProps
}

/* eslint-disable max-len */

const IbePage = ({ styles }: Props) => {
  const { formatMessage } = useIntl()

  return (
    <div>
      <SubpageHeroTeaser title={formatMessage(messages.pageTitle)} image={imageDesktop} imgAlt="office gallery" />
      <Container className={styles.InfoGraphicContainer} fluid>
        <Row className={styles.InfoGraphicRow}>
          <Col lg="6">
            <h2 className={styles.headline}>{formatMessage(messages.title)}</h2>
            <p>{formatMessage(messages.copy)}</p>
          </Col>
          <Col lg={1} />
          <Col lg="5">
            {ibeInfoGraphic}
            <small className={styles.imageCap}>
              Abbildung: Internet Booking Engine. © freshcells systems engineering
            </small>
          </Col>
        </Row>
      </Container>
      <Container className={styles.textContainer} fluid>
        <Row>
          <Col lg="8">
            <h2 className={styles.headline}>Was sind die wichtigen Bestandteile einer IBE</h2>
          </Col>
        </Row>
        <Row>
          <Col lg="6" className={styles.textContainerInner}>
            <p>
              Eine Internet Booking Engine besteht im Frontend aus einer Produktsuche, einer Ergebnisanzeige mit
              Filtermöglichkeiten, einer Produktdetailseite und einem Buchungsformular.
            </p>
            <p>
              Im Hintergrund laufen funktionale Verarbeitungsprozesse ab. Eine Internet Booking Engine besteht deswegen
              aus drei Ebenen:
            </p>
            <p>
              Das <strong>Frontend</strong> beinhaltet UI und UX, also die im Internet sichtbare IBE. Im Frontend
              Eingabedaten, zum Beispiel aus dem Buchungsformular, validiert, ergänzende Angebote (Reiseversicherungen,
              Mietwagen, Transfer und so weiter) ausgespielt und die Bezahlung eingebettet. Ist eine Buchung erfolgt,
              sorgt das Frontend für die Anzeige der Bestätigungsseite.
            </p>
            <p>
              Die Anbindung von Angebotsquellen, entweder direkt von Anbietern oder von spezialisierten Player-Systemen,
              in denen Produktkomponenten kombiniert und aufbereitet werden, sowie die Aufbereitung von Daten zur
              Weiterverarbeitung in externen Fulfillment-Systemen, sind ein weiterer wichtiger Baustein einer IBE.
              Dieser wird oft als <strong>Backend</strong> bezeichnet.
            </p>
            <p>
              Zwischen Frontend und Backend ist die Middleware angesiedelt. Ihre Aufgabe besteht darin, die
              Kommunikation zwischen Frontend und Backend zu organisieren. In der Middleware werden zum Beispiel
              Datenformate für die jeweiligen Zielsysteme aufbereitet, miteinander bzw. aufgeteilt. Ziel ist es, dass
              Daten konsolidiert und Datenübermittlungen in beide Richtungen auf ein notwendiges Maß reduziert werden.
            </p>
          </Col>
          <Col lg="6" className={styles.textContainerInner}>
            <p>
              Heute sind noch viele Internet Booking Engines individuell entwickelte Software-Lösungen, die speziell für
              einen Betreiber konzipiert und entwickelt werden. Individuelle Lösungen haben den Vorteil, dass sie
              individuell auf eine Datenquelle ausgerichtet sind und das Frontend an die Voraussetzungen und Strategien
              der Betreiber angepasst ist.
            </p>
            <p>
              Konzeption, Entwicklung und Betrieb individueller IBEs verursachen hohe Kosten. Soll zum Beispiel ein
              Anbieter ausgetauscht werden, betrifft das viele Bereiche der IBE. So entstehen lange Vorlaufzeiten und
              erneut hohe Kosten.
            </p>
            <p>
              Mit der Weiterentwicklung der Web-Technologien ergeben sich neue Möglichkeiten, und Betreiber von IBEs
              wünschen sich Alternativen.
            </p>
            <p>
              Frameworks, wie zum Beispiel die{' '}
              <a
                className={styles.textLink}
                href="https://www.freshcells.de/de/products/travelsandbox/"
                target="_blank"
                rel="noopener noreferrer"
                title="TravelSandbox Produktinformation"
              >
                TravelSandbox® von der freshcells systems engineering GmbH
              </a>
              , sind fertige Software-Lösungen, die eine Middleware, Frontend-Komponenten und flexible
              Schnittstellen-Komponenten enthalten.
            </p>
            <p>
              Fertige Internet Booking Engines werden von Produktanbietern angeboten. Kundenspezifische Anpassungen sind
              in einem eingeschränkten Rahmen möglich.
            </p>
            <p>
              Neu auf dem Markt ist Quickstart, eine komplette Internet Booking Engine, die mit unterschiedlichen
              Angebotsquellen verknüpft werden kann. Auf der Frontend-Seite stehen fertige UX-Komponenten zur Verfügung,
              die in der Enterprise-Version je nach Bedarf um individuelle Bausteine ergänzt und angepasst werden
              können. Auch mehrere{' '}
              <a
                className={styles.textLink}
                href="https://de.wikipedia.org/wiki/White_Label"
                target="_blank"
                rel="noopener noreferrer"
                title="Whitelabel auf Wikipedia"
              >
                Whitelabel
              </a>{' '}
              lassen sich mit der Quickstart realisieren.
            </p>
          </Col>
        </Row>
      </Container>
      <Container className={styles.textContainer} fluid>
        <Row>
          <Col lg="8">
            <h2 className={styles.headline}>Wofür sind IBEs sinnvoll</h2>
          </Col>
        </Row>
        <Row>
          <Col className={styles.textContainerInner}>
            <p>
              Gerade bei <strong>Reisen</strong> und Reisekomponenten sind, oftmals anders als zum Beispiel in einem
              Online-Shop, die einzelnen Bestandteile sowohl im Preis, als auch in der Verfügbarkeit, einer hohen
              Dynamik unterworfen. Das bedeutet, dass etwa bei einer Pauschalreise kaum langfristig abschätzbar ist,
              welche Angebote wie häufig verfügbar sind und was der Preis des Angebots ist. Zudem sind die Anbieter
              einzelner Reisekomponenten darauf angewiesen, ihre Kontingente langfristig zu planen und nach Möglichkeit
              über mehrere Kanäle zu verkaufen. Gerade im Tourismus sind die Preise und Kontingente für zum Beispiel
              Flüge oder Übernachtungen starken saisonalen Schwankungen unterworfen und Zahlungsmodalitäten mit Teil-
              und Restzahlungen tun ihr Übriges.
            </p>
            <p>
              Da keine Lagerhaltung wie bei klassischen Produktionsgütern existiert, sondern das Produkt in Echtzeit
              individuell zusammengestellt wird, braucht es Systeme, die in der Lage sind, mit der Dynamik umzugehen und
              den Konsumentinnen und Konsumenten ein kaufbares, klar beschriebenes Produkt anzubieten und den Kauf bzw.
              die Buchung zu steuern.
            </p>
          </Col>
        </Row>
      </Container>
      <div className={styles.contactContainer}>
        <ContactForm formClassName={'contactIBE'} formHeadline={messages.contactTitle} />
      </div>
      <div className={styles.customFooter}>
        <Link to="/privacy-policy/" className={styles.footerlink}>
          Datenschutz
        </Link>
        <Link to="/imprint/" className={styles.footerlink}>
          Impressum
        </Link>
      </div>
    </div>
  )
}

/* eslint-enable max-len */

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(IbePage)
